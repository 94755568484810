import isPresent from 'is-present'
import isBlank from 'is-blank'
import moment from "moment-timezone";
import moment_with_data from './moment_with_data';

const TRUE_VALUES=[true, 1, '1', 't', 'T', 'true', 'TRUE', 'on', 'ON', 'yes', 'YES', 'Yes']
const STATES  = ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DC", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA",
  "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN",
  "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"].sort()
const FEATURES = [
  "photos","links","logo","social_links","show_map",
  "offline_payment","offline_payment_restriction","record_custom_payments",
  "emails","user_roles","statistics","registration_kiosk",
  "program_history","preload_users","attendance","invitations","mobile_app",
  "viewer_bulk_email","translations","injury_management","text_messaging"
]
const REG_STATES = ['expired', 'pending', 'draft', 'completed', 'listed_canceled', 'canceled', 'correction_required']
const PAYMENT_STATES = ['none', 'void', 'pending', 'partial', 'partial_refunded', 'refunded', 'completed', 'canceled', 'failed']
const APPROVAL = ['approved', 'not_approved']

export default {
  data (){
    return {
      TRUE_VALUES,
      STATES,
      REG_STATES,
      PAYMENT_STATES,
      APPROVAL,
      FEATURES,
      timePickerOpts: {enableTime: true, dateFormat: "Y-m-d H:i", altFormat: 'M d, Y H:i',altInput:true},
      datePickerOpts: {enableTime: false, dateFormat: "Y-m-d", altFormat: 'M d, Y', altInput:true, wrap:true},
    }
  },
  methods: {
    async signinAs(obj, opts = {}){
      const resp = await this.axios.get(`/api2/admin/users/${obj.id}/tokens/signin_as`)
      const params = {...resp.data.object, ...opts}
      const queryString = Object.keys(params).map(k=> encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
      const url = `${this.$fid.accounturl}/signin_as?${queryString}`
      Object.assign(document.createElement('a'), { target: '_blank', href: url}).click();
    },
    canSigninAs(){
        return !this.$store.state.current_user.permissions.support_access
    },
    isPresent(o){
      return isPresent(o)
    },
    isBlank(o){
      return isBlank(o)
    },
    t(str, opts={}){
      this.$root.$children[0].translations //hack to make lazy load reactive
      return I18n.t(str,opts)
    },
    l(str, opts={}){
      this.$root.$children[0].translations //hack to make lazy load reactive
      return I18n.toTime(opts.format,str)
    },
    to_br(str){
      if (!str) return ""
      else return str.replace(/\r?\n/g, "<br>")
    },
    titleize(str){
      return str.toLowerCase().replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
    },
    from_unix(unix,format="MM/DD/YYYY HH:mm"){
      return moment.unix(unix).tz(this.$store.state.current_user.time_zone||"America/New_York").format(format)
    },
    time_from(str,format="MM/DD/YYYY HH:mm"){
      return moment(str).tz("UTC").format(format)
    },
    time_from_zone(str, format="MM/DD/YYYY HH:mm"){
      if (str !== undefined){
        const userTimeZone=moment_with_data(str).tz(window.localStorage.getItem("time_zone_name")).format(format)
        return userTimeZone
      }else { 
        return moment(str).tz("UTC").format(format) 
      }
    },
    mclone(o){
      return JSON.parse(JSON.stringify(o))
    },
    goTo(hash){
      if (hash.filter) hash.filter = JSON.stringify(hash.filter)
      return {query: {...this.mclone(this.$route.query),...hash}}
    },
    jsGoTo(hash){
      const clearCache = this.mclone(hash)
      return this.$router.push(this.goTo(clearCache))
    },
    orderHash(attr){
      if (this.$route.query.order === attr){
        const dir = this.$route.query.direction || 'asc'
        if(dir === 'asc'){
          return {order: attr, direction: 'desc'}
        }else{
          return {order: attr, direction: 'asc'}
        }
      }else{
        return {order: attr, direction: 'asc'}
      }
    }
  },
  computed: {
    route (){ return this.$route.params },
    state (){ return this.$store.state },
  }
}
